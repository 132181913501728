
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { GeneralSurveyClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class surveyQuestionAnswersModal extends Vue {

    @Prop() surveyIdentifier: string;

    @Prop({
        default: () => new OM.GeneralSurveyQuestion()
    }) surveyQuestion: OM.GeneralSurveyQuestion;

    allAnswers: OM.GeneralSurveyAnswerToQuestionVM[] = [];

    created() {
        GeneralSurveyClient.getAnswersToQuestion(this.surveyIdentifier, this.surveyQuestion.identifier)
        .then(x => {
            this.allAnswers = x;
        })
    }

}
