import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "modal bg_white column" }
const _hoisted_2 = { class: "page_padding text-center" }
const _hoisted_3 = { class: "page_padding" }
const _hoisted_4 = { class: "row mx-0" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "page_padding flex_centered column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_form_field = _resolveComponent("form-field")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (!_ctx.surveyQuestion.identifier)
        ? (_openBlock(), _createBlock(_component_localized_text, {
            key: 0,
            type: "h2",
            class: "text-center",
            localizedKey: "backoffice_NuovaDomanda",
            text: "Nuova Domanda"
          }))
        : (_openBlock(), _createBlock(_component_localized_text, {
            key: 1,
            type: "h2",
            class: "text-center",
            localizedKey: "backoffice_ModificaDomanda",
            text: "Modifica Domanda"
          }))
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", _hoisted_4, [
        _createVNode("div", _hoisted_5, [
          _createVNode(_component_form_field, {
            type: "text",
            modelValue: _ctx.localModel.text,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.localModel.text = $event)),
            labelKey: "backoffice_Domanda",
            labelValue: "Domanda",
            placeholderKey: "backoffice_Domanda",
            placeholderValue: "Domanda"
          }, null, 8, ["modelValue"])
        ])
      ])
    ]),
    _createVNode("div", _hoisted_6, [
      _createVNode("button", {
        class: "fev_button big",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "backoffice_Annulla",
          text: "Annulla"
        })
      ]),
      _createVNode("button", {
        class: "fev_button big violet mt-3",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.save()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "backoffice_Salva",
          text: "Salva"
        })
      ])
    ])
  ]))
}