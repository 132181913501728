
import { Options, Vue } from 'vue-class-component';
import { GeneralSurveyClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';
import surveyQuestionModal from '../modal/surveyQuestionModal.vue';
import surveyQuestionAnswersModal from '../modal/surveyQuestionAnswersModal.vue';
import * as OM from '@/Model';
import store from '@/store';

@Options({
    components: {
    }
})
export default class Survey extends Vue {

    surveyIdentifier: string = "";
    survey: OM.GeneralSurveyVM = new OM.GeneralSurveyVM();

    created(){
        this.surveyIdentifier = this.$route.params.identifier as string;
        this.init();
    }

    init(){
        if(!this.surveyIdentifier)
            return;

        GeneralSurveyClient.getById(this.surveyIdentifier)
        .then( x => {
            this.survey = x;
        })
    }

    addOrEditQuestion(question: OM.GeneralSurveyQuestion = new OM.GeneralSurveyQuestion()){
        this.$opModal.show(surveyQuestionModal, {
            surveyQuestion: question,
            callback: (questionModel: OM.GeneralSurveyQuestion) => {
                var editQuestionModel = new OM.EditGeneralSurveyVM();
                editQuestionModel.generalSurveyIdentifier = this.survey.identifier;
                editQuestionModel.question = questionModel;
                GeneralSurveyClient.editQuestionSurvey(editQuestionModel)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }

    showQuestionAnswers(question: OM.GeneralSurveyQuestion){
        this.$opModal.show(surveyQuestionAnswersModal, {
            surveyIdentifier: this.survey.identifier,
            surveyQuestion: question
        })
    }

    save(){
        if(!this.survey.identifier)
            this.survey.identifier = store.state.emptyGuid;

        GeneralSurveyClient.editSurveyName(this.survey.identifier, this.survey.name)
        .then( x => {
            this.surveyIdentifier = x;
            this.init();
        })
    }

    get isDisabled(){
        return !this.survey.name.trim();
    }

    publishQuestion(){
        if(!this.surveyIdentifier)
            return;

        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("backoffice_Attenzione", "Attenzione"),
            subtitle: this.$localizationService.getLocalizedValue("backoffice_SeiSicuroDiVolerPubblicareQuestoQuestionario", "Sei sicuro di voler pubblicare questo questionario?"),
            confirm: this.$localizationService.getLocalizedValue("backoffice_Si", "Si"),
            confirmCb: () => {
                this.$opModal.closeLast();
                this.publish();
            },
            deny: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    publish(){
        GeneralSurveyClient.publishById(this.surveyIdentifier)
        .then( x => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("backoffice_Successo", "Successo"),
                subtitle: this.$localizationService.getLocalizedValue("backoffice_PubblicatoConSuccesso", "Pubblicato con successo!"),
                confirm: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
                confirmCb: () => {
                    this.init();
                    this.$opModal.closeLast();
                }
            })
        })
    }

    // deleteSurvey(){
    //     if(!this.surveyIdentifier)
    //         return;

    //     this.$opModal.show(InfoModal, {
    //         text: this.$localizationService.getLocalizedValue("backoffice_Attenzione", "Attenzione"),
    //         subtitle: this.$localizationService.getLocalizedValue("backoffice_SeiSicuroDiVolerEliminareQuestoQuestionario", "Sei sicuro di voler eliminare questo questionario?"),
    //         confirm: this.$localizationService.getLocalizedValue("backoffice_Si", "Si"),
    //         confirmCb: () => {
    //             this.$opModal.closeLast();
    //             this.delete();
    //         },
    //         deny: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
    //         denyCb: () => {
    //             this.$opModal.closeLast();
    //         }
    //     })
    // }

    // delete(){
    //     GeneralSurveyClient.deleteById(this.surveyIdentifier)
    //     .then( x => {
    //         this.$opModal.show(InfoModal, {
    //             text: this.$localizationService.getLocalizedValue("backoffice_Successo", "Successo"),
    //             subtitle: this.$localizationService.getLocalizedValue("backoffice_EliminatoConSuccesso", "Eliminato con successo!"),
    //             confirm: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
    //             confirmCb: () => {
    //                 this.$router.replace("/survey");
    //                 this.$router.push("/survey-list")
    //                 this.$opModal.closeLast();
    //             }
    //         })
    //     })
    // }
}
