
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class surveyQuestionModal extends Vue {

    @Prop({
        default: () => new OM.GeneralSurveyQuestion()
    }) surveyQuestion: OM.GeneralSurveyQuestion;
    
    @Prop({
        type: [Function]
    }) callback: any;

    localModel: OM.GeneralSurveyQuestion = null;

    created() {
        this.localModel = JSON.parse(JSON.stringify(this.surveyQuestion));
    }

    save() {
        this.callback(this.localModel);
    }

    close() {
        this.$emit('close');
    }
}
